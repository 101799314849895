import { AdminDropdownsBaseController } from './admin_dropdowns_base_controller';

export default class extends AdminDropdownsBaseController {
  static targets = ['component', 'menu', 'menuItem'];

  static values = {
    width: String
  }

  connect() {
    this.clickHandler = this._handleOutsideClick.bind(this);
  }

  toggleMenu() {
    this.closeAnyOpenMenu();
    this._positionMenu(event.target);
    this.showMenu();

    // This will reduce the number of events fired on the page by only listening to clicks when a
    // menu is open.
    if (this.menuTarget.classList.contains('open')) {
      document.addEventListener('click', this.clickHandler);
    } else {
      document.removeEventListener('click', this.clickHandler);
    }
  }

  closeDropdown() {
    this.closeMenu();
  }

  _positionMenu() {
    const closestRelativeElem = event.target.closest('.relative');

    const dropdownCoords = this.componentTarget.getBoundingClientRect();
    let closestRelativeElemCoords;

    if (closestRelativeElem) {
      closestRelativeElemCoords = closestRelativeElem.getBoundingClientRect();
    }

    this._setMenuLeft(dropdownCoords, closestRelativeElemCoords);
    this._setMenuTop(dropdownCoords, closestRelativeElemCoords);
    this.menuTarget.style.width = `${ this.widthValue}`;
  }
}
