import { Controller } from "stimulus";

export class AdminDropdownsBaseController extends Controller {
  closeAnyOpenMenu() {
    const openMenu = document.querySelector('.js-admin-dropdown-menu.open');

    if (openMenu && openMenu.id !== this.menuTarget.id) {
      openMenu.classList.toggle('hidden');
      openMenu.classList.toggle('open');

      const dropdown = openMenu.closest('.js-admin-dropdown-container');
      const dropdownIcon = dropdown.querySelector('.js-admin-dropdown-container-icon');

      if (dropdownIcon) {
        dropdownIcon.classList.toggle('rotate-180');
      }
      dropdown.classList.toggle('hover:border-purple-300');
    }
  }

  closeMenu() {
    if (this.menuTarget.classList.contains('open')) {
      this.menuTarget.classList.remove('open');
      this.menuTarget.classList.add('hidden');

      if (this.hasIconTarget) {
        this.iconTarget.classList.remove('rotate-180');
      }
    }

    document.removeEventListener('click', this.clickHandler);
  }

  positionMenu(target) {
    const closestRelativeElem = target.closest('.relative');

    const dropdownCoords = this.componentTarget.getBoundingClientRect();
    let closestRelativeElemCoords;

    if (closestRelativeElem) {
      closestRelativeElemCoords = closestRelativeElem.getBoundingClientRect();
    }

    this._setMenuWidth(dropdownCoords);
    this._setMenuLeft(dropdownCoords, closestRelativeElemCoords);

    if (this.directionValue === 'up') {
      this._setMenuBottom(dropdownCoords, closestRelativeElemCoords);
    } else {
      this._setMenuTop(dropdownCoords, closestRelativeElemCoords);
    }
  }

  showMenu() {
    this.menuTarget.classList.toggle('open');
    this.menuTarget.classList.toggle('hidden');

    if (this.hasIconTarget) {
      this.iconTarget.classList.toggle('rotate-180');
    }

    this.componentTarget.classList.toggle('hover:border-purple-300');
  }

  _onResize() {
    this.positionMenu(this.componentTarget);
  }

  _handleOutsideClick(event) {
    if (!this.element.contains(event.target)) {
      this.closeMenu();
    }
  }

  _setMenuWidth(dropdownCoords) {
    this.menuTarget.style.width = `${ dropdownCoords.width }px`;
  }

  _setMenuLeft(dropdownCoords, closestRelativeElemCoords) {
    if (closestRelativeElemCoords) {
      this.menuTarget.style.left = `${ dropdownCoords.left - closestRelativeElemCoords.left }px`;
    } else {
      this.menuTarget.style.left = `${ dropdownCoords.left }px`;
    }
  }

  _setMenuTop(dropdownCoords, closestRelativeElemCoords) {
    if (closestRelativeElemCoords) {
      this.menuTarget.style.top = `${ dropdownCoords.bottom - closestRelativeElemCoords.top + 1.5 }px`;
    } else {
      this.menuTarget.style.top = `${ dropdownCoords.bottom + 1.5 }px`;
    }
  }

  _setMenuBottom(dropdownCoords, closestRelativeElemCoords) {
    const selectHeight = this.componentTarget.offsetHeight;

    if (closestRelativeElemCoords) {
      this.menuTarget.style.bottom = `${ closestRelativeElemCoords.bottom - dropdownCoords.bottom + selectHeight + 2.5 }px`;
    } else {
      this.menuTarget.style.bottom = `${ dropdownCoords.bottom + selectHeight + 2.5 }px`;
    }
  }
}
