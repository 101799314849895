import { Controller } from "stimulus";
import Rails, { ajax } from "@rails/ujs";

export default class extends Controller {
  static targets = ["content", "form", "formElement"];

  static values = {
    url: String,
    confirmMessage: String
  };

  toggleEditor() {
    if (this.confirmMessageValue) {
      Rails.confirm(null, this.formElementTarget, {
        confirmMessage: this.confirmMessageValue
      }, {
        confirmCallback: () => {
          this._showEditor();
        }})
    } else {
      this._showEditor();
    }
  }

  _showEditor() {
    this.originalTextValue = this.formElementTarget.value;

    this.formTarget.classList.remove("hidden");
    this.contentTarget.classList.add("hidden");

    this.formElementTarget.focus();
    this.formElementTarget.selectionStart = this.formElementTarget.value.length;
  }

  cancel() {
    this.formTarget.classList.add("hidden");
    this.contentTarget.classList.remove("hidden");
    this.formElementTarget.value = this.originalTextValue;
  }

  submit(event) {
    event.preventDefault();

    const body = new FormData();
    body.append(this.formElementTarget.name, this.formElementTarget.value);

    ajax({
      url: this.urlValue,
      type: "POST",
      data: body,
      success: () => {}
    })
  }
}
