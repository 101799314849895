import { AdminDropdownsBaseController } from './admin_dropdowns_base_controller';
import {ajax} from "@rails/ujs";

let debounce = require('lodash/debounce');

export default class extends AdminDropdownsBaseController {
  static targets = ['component', 'menu', 'icon', 'menuItem', 'hiddenField'];

  static values = {
    url: String,
    method: String
  }

  connect() {
    this.clickHandler = this._handleOutsideClick.bind(this);
    this.toggleOptionAll = debounce(this.toggleOptionAll, 150).bind(this);
  }

  toggleMenu(event) {
    this.closeAnyOpenMenu();
    this.positionMenu(event.target);
    this.showMenu();

    // This will reduce the number of events fired on the page by only listening to clicks when a
    // menu is open, and only listening to resize event when a menu is open.
    if (this.menuTarget.classList.contains('open')) {
      document.addEventListener('click', this.clickHandler);
      window.addEventListener('resize', this._onResize.bind(this));
    } else {
      document.removeEventListener('click', this.clickHandler);
      window.removeEventListener('resize', this._onResize);
    }
  }

  toggleOption() {
    this._toggleOption();
  }

  toggleOptionAll() {
    this._toggleOption();
  }

  _toggleOption() {
    let checkedItems = [];
    this.menuItemTargets.forEach((item) => {
      if (item.querySelector('input[type="checkbox"]').checked) {
        checkedItems.push(item.dataset.value);
      }

    });

    this.hiddenFieldTarget.value = checkedItems.join(' ');

    this._loadData();
  }

  _loadData() {
    if (this.ajaxRequest) {
      this.ajaxRequest.abort(); // Abort previous ajax request.
    }

    const body = new FormData();
    body.append(this.hiddenFieldTarget.name, this.hiddenFieldTarget.value);

    ajax({
      url: this.urlValue,
      type: this.methodValue || "GET",
      data: body,
      dataType: "script",
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      }
    })
  }
}
