import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content", "toggler", "icon", "header", "hiddenOnExpand", "setExpanded"];
  static values = { group: String };

  toggle() {
    if (this.contentTarget.classList.contains("collapsed")) {
      this.expand();
    } else {
      this.collapse();
    }
  }

  expand() {
    this._getContentTargetHeightWhenReady().then(() => {
      this.contentTarget.classList.remove("collapsed");

      if (this.hasIconTarget) {
        this.iconTarget.classList.remove("collapsed");
      }

      if (this.hasHeaderTarget) {
        this.headerTarget.classList.remove("truncate");
      }

      if (this.hasSetExpandedTarget) {
        this.setExpandedTarget.classList.add("expanded");
      }

      this.contentTarget.addEventListener(
        "transitionend",
        function clearHeight(event) {
          this.contentTarget.removeEventListener("transitionend", clearHeight);
          this.contentTarget.style.height = "";
        }.bind(this),
      );

      if (this.animationIsDisabledFor(this.contentTarget)) {
        this.contentTarget.dispatchEvent(new Event("transitionend"));
      }

      if (this.hasHiddenOnExpandTarget) {
        this.hiddenOnExpandTargets.forEach((element) => {
          element.classList.add("hidden");
        });
      }

      if (this.hasGroupValue) this._collapseOtherAccordions();
    });
  }

  collapse() {
    this.contentTarget.style.height = this.contentTarget.scrollHeight + "px";

    window.setTimeout(
      function () {
        this.contentTarget.style.height = "0";
      }.bind(this),
      50
    );

    this.contentTarget.classList.add("collapsed");

    if (this.hasIconTarget) {
      this.iconTarget.classList.add("collapsed");
    }

    if (this.hasHeaderTarget) {
      this.headerTarget.classList.add("truncate");
    }

    if (this.hasHiddenOnExpandTarget) {
      this.hiddenOnExpandTargets.forEach((element) => {
        element.classList.remove("hidden");
      });
    }

    if (this.hasSetExpandedTarget) {
      this.setExpandedTarget.classList.remove("expanded");
    }
  }

  animationIsDisabledFor(element) {
    return window.getComputedStyle(element).transitionDuration === "0s";
  }

  _getContentTargetHeightWhenReady() {
    return new Promise((resolve) => {
      let attempts = 0;
      const maxAttempts = 20;

      const checkContentHeight = () => {
        attempts++;

        if (this.contentTarget.scrollHeight > 0) {
          this.contentTarget.style.height = this.contentTarget.scrollHeight + "px";
          resolve();
        } else if (attempts >= maxAttempts) {
          reject(new Error("Element height could not be determined."));
        } else {
          setTimeout(checkContentHeight, 50);
        }
      };

      checkContentHeight();
    });
  }

  _collapseOtherAccordions() {
    Array.from(
      document.querySelectorAll(
        `[data-components--accordion-group-value="${this.groupValue}"]`
      )
    ).forEach((element) => {
      if (element === this.element) return;

      const controller = this.application.getControllerForElementAndIdentifier(
        element,
        this.identifier,
      );
      const content = element.querySelector(".accordion-content");

      if (!content || content.classList.contains("collapsed") || content.style.height === "0px") {
        return;
      }

      controller.collapse();
    });
  }
}
