import { Controller } from "stimulus";
import {ajax} from "@rails/ujs";

let debounce = require('lodash/debounce');

export default class extends Controller {
  static targets = ['input', 'checkbox'];
  static values = { url: String };

  connect() {
    this.selectedIdsValue = [];
    this.resetOnClick = debounce(this.resetOnClick, 100).bind(this);
    this.filterOnInput = debounce(this.filterOnInput, 500).bind(this);
  }

  filterOnInput() {
    this._loadData();
  }

  resetOnClick() {
    this.inputTarget.value = "";
    this._setCheckboxesParams();

    this._loadData();
  }

  _loadData(searchParam) {
    if (this.ajaxRequest) {
      this.ajaxRequest.abort(); // Abort previous ajax request.
    }

    ajax({
      url: this._url(searchParam),
      type: "GET",
      dataType: "script",
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      }
    })
  }

  _url() {
    this._setCheckboxesParams();

    const queryParams = [
      this._searchParam(),
      this._selectedIdsParam()
    ]

    return queryParams.length ? `${this.urlValue}?${queryParams.join("&")}` : this.urlValue;
  }

  _searchParam() {
    const search = this.inputTarget.value.trim();
    return search ? `search=${encodeURIComponent(search)}` : "";
  }

  _selectedIdsParam() {
    return this.selectedIdsValue.length ? `checked_ids=${this.selectedIdsValue.join(",")}` : "";
  }


  _setCheckboxesParams() {
    let selectedIds = [...this.selectedIdsValue];

    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.checked && !selectedIds.includes(checkbox.value)) {
        selectedIds.push(checkbox.value);
      } else if (!checkbox.checked) {
        selectedIds = selectedIds.filter((id) => id !== checkbox.value);
      }
    });

    this.selectedIdsValue = selectedIds;
  }
}
