import { Controller } from "stimulus";
import {ajax} from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "contentPageContainer",
    "expandContentPageIcon",
    "contentPageContent",
    "tasksContainer",
    "tasksTitle",
    "expandTasksIcon",
    "tasksContent",
    "collapseContentPageIcon",
    "collapseTasksIcon",
  ];

  collapseContentPage() {
    this.contentPageContainerTarget.classList.add("flex", "w-8");
    this.contentPageContainerTarget.classList.remove("w-full", "overflow-y-auto");
    this.expandContentPageIconTarget.classList.remove("hidden");
    this.contentPageContentTargets.forEach((content) => {
      content.classList.add("hidden");
    });
    this.collapseContentPageIconTarget.classList.add("hidden");
    this.collapseTasksIconTarget.classList.add("hidden");

    this._updateState('fullTasks');
  }

  expandContentPage() {
    this.contentPageContainerTarget.classList.add("w-full", "overflow-y-auto");
    this.contentPageContainerTarget.classList.remove("w-8", "overflow-hidden", "flex");
    this.expandContentPageIconTarget.classList.add("hidden");
    this.contentPageContentTargets.forEach((content) => {
      content.classList.remove("hidden");
    });
    this.collapseContentPageIconTarget.classList.remove("hidden");
    this.collapseTasksIconTarget.classList.remove("hidden");

    this._updateState('divided');
  }

  collapseTasks() {
    this.tasksContainerTarget.classList.add("w-8", "flex");
    this.tasksContainerTarget.classList.remove("w-full", "overflow-y-auto");
    this.tasksTitleTarget.classList.add("hidden");
    this.expandTasksIconTarget.classList.remove("hidden");
    this.tasksContentTarget.classList.add("hidden");
    this.collapseContentPageIconTarget.classList.add("hidden");
    this.collapseTasksIconTarget.classList.add("hidden");

    this._updateState('fullContentPage');
  }

  expandTasks() {
    this.tasksContainerTarget.classList.add("w-full", "overflow-y-auto");
    this.tasksContainerTarget.classList.remove("w-8", "overflow-hidden", "flex");
    this.tasksTitleTarget.classList.remove("hidden");
    this.expandTasksIconTarget.classList.add("hidden");
    this.tasksContentTarget.classList.remove("hidden");
    this.collapseContentPageIconTarget.classList.remove("hidden");
    this.collapseTasksIconTarget.classList.remove("hidden");

    this._updateState('divided');
  }

  _updateState(state) {
    ajax({
      url: `${window.location.origin}/admin/editor_collapse`,
      type: "PATCH",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({ state: state }),
    });
  }
}
