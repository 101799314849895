import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["integration", "integrationError", "courseId"];

  validate(event) {
    // Ensure integration is not empty
    this._validateIntegration(event);
    // Validate course ID format for Cornerstone
    this.validateCornerstoneId(event);
  }

  validateCornerstoneId(event) {
    const courseIdValue = this._getCourseIdValue();
    const integrationId = this._getSelectedIntegrationId();
    const errorMessageEl = this._getErrorMessageElement();

    const selectedIntegrationType = this._getSelectedIntegrationType(integrationId);

    if (this._validCourseIdFormat(selectedIntegrationType, courseIdValue)) {
      event.preventDefault();
      this._displayCornerstoneIdError(errorMessageEl);
    } else {
      this._removeCornerstoneIdError(errorMessageEl);
    }
  }

  _validateIntegration(event) {
    const errorMessageEl = this.integrationErrorTarget
    const integrationId = this._getSelectedIntegrationId();

    if (!integrationId) {
      event.preventDefault();
      this._displayIntegrationError(errorMessageEl);
    } else {
      this._removeIntegrationError(errorMessageEl)
    }
  }

  _getSelectedIntegrationId() {
    const selectedOption = this.integrationTarget.querySelector(
      'input[data-components--admin-dropdowns-dropdown-target="hiddenField"]'
    );
    return selectedOption?.value.trim();
  }

  _getCourseIdValue() {
    const courseIdInput = this.courseIdTarget.querySelector('input');
    return courseIdInput?.value.trim();
  }

  _getErrorMessageElement() {
    return this.courseIdTarget.querySelector('.form-error');
  }

  _getSelectedIntegrationType(integrationId) {
    const selectedMenuItem = this.integrationTarget.querySelector(
      `[data-components--admin-dropdowns-dropdown-target="menuItem"][data-value="${integrationId}"]`
    );
    return selectedMenuItem?.getAttribute('data-type')?.toLowerCase();
  }

  _validCourseIdFormat(integrationType, courseIdValue) {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return integrationType === "cornerstone" && !uuidRegex.test(courseIdValue);
  }

  _displayCornerstoneIdError(errorMessageEl) {
    errorMessageEl?.parentElement?.parentElement?.classList.remove("hidden");
    this.courseIdTarget.querySelector('[id="metadata[course_id]"]').style.borderColor = "#FF857E"
  }

  _removeCornerstoneIdError(errorMessageEl) {
    errorMessageEl?.parentElement?.parentElement?.classList.add("hidden");
    this.courseIdTarget.querySelector('[id="metadata[course_id]"]').style.borderColor = "#AEB1B9"
  }

  _displayIntegrationError(errorMessageEl) {
    errorMessageEl.classList.remove("hidden")
    this.integrationTarget.querySelector('div:first-child').style.borderColor = "#FF857E"
  }

  _removeIntegrationError(errorMessageEl) {
    errorMessageEl.classList.add("hidden")
    this.integrationTarget.querySelector('div:first-child').style.borderColor = "#AEB1B9"
  }
}
